import React from "react"
import PropTypes from "prop-types"

import Nav from "./nav"
import "./normalize.css";
import "./layout.css"
import styled from "styled-components";
import COLORS from "./../styles/colors";
import { Link } from "gatsby"


const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 100%;
`;

const StyledMain = styled.section`
    flex: 1;
`;

const FooterWrapper = styled.footer`

`;

const IntroContainer = styled.div`
    background-color: ${COLORS.primaryColor};
    display: grid;
    grid-template-areas: "social contact";

    @media screen and (max-width: 800px){
        grid-template-areas: "contact" "social";
    }
`;

const IntroPanel = styled.div`
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const IntroLeft = styled(IntroPanel)`
display: flex;
flex-direction: column;
    h1 {
        color: #fff;
        font-weight: 300;
        line-height: 2.5rem;
        font-size: 2.2rem;
    }

@media screen and (max-width: 800px) {
    margin-bottom: 2rem;
}
`;

const StyledLink = styled(Link)`
    border: 2px solid #fff;
    padding: 0.5rem 1rem;
    text-decoration: none;
    transition: all  0.5s;
    display: inline-block;
    font-weight: normal;
    &:hover {
        background-color: #fff;
        color: ${COLORS.primaryColor} !important;
    }
`;

const Layout = ({children}) => {
    return (
        <StyledDiv>
            <Nav />
            <StyledMain>{children}</StyledMain>
            <FooterWrapper>
            <IntroContainer>
                <IntroLeft style={{gridArea: "social"}}>
                <h3 style={{margin: 0, color: "#fff", fontWeight: 300}}>Follow s3strategies on Social Media</h3>
                <div>
                    <a href="https://www.facebook.com/s3strategiesPD/" target="_blank" rel="noopener noreferrer"><img src="https://cdn3.iconfinder.com/data/icons/social-media-black-white-2/512/BW_Facebook_glyph_svg-128.png"
                        alt="facebook" className="footer-social-icon" /></a>
                    <a href="https://twitter.com/s3strategies" target="_blank" rel="noopener noreferrer"><img src="https://cdn3.iconfinder.com/data/icons/social-media-black-white-2/512/BW_Twitter_glyph_svg-128.png"
                        alt="twitter" className="footer-social-icon" /></a>
                    <a href="https://www.instagram.com/s3strategies/" target="_blank" rel="noopener noreferrer"><img src="https://cdn4.iconfinder.com/data/icons/social-media-black-white-2/600/Instagram_glyph_svg-128.png"
                        alt="instagram" className="footer-social-icon" /></a>
                </div>
                <div>© {new Date().getFullYear()} <b>s3strategies</b></div>
                </IntroLeft>
                <IntroPanel style={{padding: "3rem 0", color: "#fff", gridArea:"contact"}}>
                    <div style={{fontWeight: 300}}>
                        <h1 style={{marginTop: 0}}>Schedule a Workshop</h1>
                        <p>Let us customize a learning experience aligned to your needs.</p>
                        <StyledLink to="/contact/" style={{color: "#fff"}}>Contact Us</StyledLink>
                    </div>
                </IntroPanel>
            </IntroContainer>
                
            </FooterWrapper>
        </StyledDiv>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
