import styled from "styled-components";
import React, {Component} from "react"
import NewIconImage from "./../../../images/new-icon.png";

export const WorkshopHeading = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const WorkshopDiv = styled.div`
    position: relative;
    background-color: #fff;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(33.33% - 4rem - 2px);
    margin: 0.5rem;
    padding: 1.5rem;
    border: 1px solid #ddd;
    transition: background-color 0.3s;
    &:hover {
        cursor: pointer;
        background-color: #fafafa;
    };

    @media screen and (max-width: 1100px) {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(50% - 4rem - 2px);
    }
`;

export const WorkshopTitle = styled.h3`
    margin: 0 0 0.5rem;
`;

export const WorkshopDescription = styled.div`
    overflow: hidden;
    font-weight: 300;
    transition: max-height 0.3s;
`;

export const WorkshopTags = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
`;

export const Tag = styled.span`
    display: inline-block;
    padding: 0rem 0.5rem;
    font-size: 75%;
    margin-right: 1rem;
    border-radius: 5px;
    background: ${props => {
        switch (props.type) {
            case "k-12":
                return "#d3d5fa";
            case "elementary":
                return "#acfde8";
            case "secondary":
                return "#f4eda9";
            default:
                return "#ccc";
        }
    }};
`;

export const NewIcon = styled.img`
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    padding: 1.5rem;
    background: url(${NewIconImage});
    background-size: contain;
    background-position: top left;
`;

class Workshop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: []
        }
    }

    toggle = (workshopId) => {
        let open = [...this.state.open];
        if (open.includes(workshopId)) {
            open = open.filter(id => id !== workshopId);
        }
        else {
            open.push(workshopId);
        }
        this.setState({open})
    }

    open = (workshopId) => {
        return this.state.open.includes(workshopId);
    }

    render() {
        const {workshop, index} = this.props;
        if (!workshop) return (<div>Hello</div>);
        return (
        <WorkshopDiv onClick={() => this.toggle(index)}>
            {workshop.new ? <NewIcon /> : ""}
            <WorkshopHeading>
                <WorkshopTitle>{workshop.title}</WorkshopTitle>
            </WorkshopHeading>
            <WorkshopDescription open={() => this.open(index)}>{this.open(index) ? workshop.description : `${workshop.description.split(" ").filter((words, index) => index < 15).join(" ")}...`}</WorkshopDescription>
            <WorkshopTags>
                {workshop.elem && <Tag type="elementary">Elementary</Tag>}
                {workshop.second && <Tag type="secondary">Secondary</Tag>}
            </WorkshopTags>
        </WorkshopDiv>
        );
    }
};

export default Workshop;