import {Link, navigate} from "gatsby"
import React, {Fragment, useLayoutEffect, useState} from "react"
import styled from "styled-components";
import COLORS from "./../styles/colors";

import ImageLogo from "./../images/logo-1-2x.png";
import WordsLogo from "./../images/s3-logo-1.png";
import HamburgerIconImage from "./../images/hamburger-icon.png";

const StyledNav = styled.nav`
    z-index: 100;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    position: sticky;
    top: 0;
    left: 0;
    /* Required from layout.css html{} */
    @media screen and (min-width: 960px) {
        padding-right: 2rem;
    }
    @media screen and (max-width: 700px) {
        display: none;
    }
`;

const NavItemContainer = styled.div`
   text-align: right;
   flex: 1;
`;

const NavItem = styled(Link)`
    padding: 0.5rem;
    margin: 0.5rem;
    font-size: 1.1rem;
    text-decoration: none;
    color: #333;
    border-bottom: 3px solid transparent;
    &:visited {
        color: #333;
    }
    &.active {
        border-bottom: 3px solid ${COLORS.primaryColor};
    }

    @media screen and (max-width: 960px) {
        font-size: 1rem;
    }

    @media screen and (max-width: 800px) {
        padding: 0.25rem;
        margin: 0.25rem;
    }

    @media screen and (max-width: 640px) {
        font-size: 0.8rem;
    }
`;

const Logo = styled(Link)`
    padding: 1rem;
    display: flex;
    align-items: center;
    flex-direction: row;
`;

const StyledImg = styled.img`
    display: inline-block;
    max-width: 250px;
    @media screen and (max-width: 960px) {
        max-width: 200px;
    }
    @media screen and (max-width: 800px) {
        max-width: 200px;
        width: 100%;
    }
`;

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

const MobileNav = styled.div`
    display: none;
    z-index: 100;
    flex-direction: column;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    position: sticky;
    top: 0;
    left: 0;
    @media screen and (max-width: 700px) {
        display: flex;
    }
`;

const MobileHeading = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const MobileItems = styled.div`
    flex-direction: column;
    display: ${props => props.open ? "flex" : "none"};
`;

const MobileNavItem = styled.div`
    padding: 1rem;
    text-decoration: none;
    color: #333;
    &:visited {
        color: #333;
    }
    &:hover {
        background-color: #ddd;
    }
    &:hover {
        cursor: pointer;
    }
    border-bottom: 1px solid #eee;
`;

const HamburgerIcon = styled.div`
    background: url(${HamburgerIconImage});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0.5rem 1rem;

    &:hover {
        cursor: pointer;
    }
`;

const MobileLogo = styled.img`
    display: block;
    max-width: 150px;
    margin: 0.25rem auto;
`;

const MobileTitle = styled.div`
    flex: 1;
    margin-left: calc(50% - 3.5rem);
    transform: translateX(-50%);
`;



const Nav = () => {
    const [width] = useWindowSize();
    const logo = width >= 960 ? ImageLogo : WordsLogo;
    const [mobileOpen, setMobileOpen] = useState(false)

    return (
        <Fragment>
            <StyledNav>
                <Logo
                    to="/"
                    style={{
                        textDecoration: `none`,
                    }}
                >
                    <StyledImg src={logo} alt="logo" />
                </Logo>
                <NavItemContainer>
                    <NavItem to="/" activeClassName="active">Home</NavItem>
                    <NavItem to="/expertise/" activeClassName="active">Expertise</NavItem>
                    <NavItem to="/workshops/" activeClassName="active" partiallyActive={true}>Workshops</NavItem>
                    {/* <NavItem to="/news/" activeClassName="active" partiallyActive={true}>News</NavItem> */}
                    <NavItem to="/contact/" activeClassName="active">Contact</NavItem>
                </NavItemContainer>
            </StyledNav>
            <MobileNav>
                <MobileHeading>
                    <HamburgerIcon onClick={() => setMobileOpen(!mobileOpen)} />
                    <MobileTitle><MobileLogo src={ImageLogo} alt="logo" /></MobileTitle>
                </MobileHeading>
                <MobileItems open={mobileOpen}>
                <MobileNavItem onClick={() => {navigate("/"); setMobileOpen(false)}}>Home</MobileNavItem>
                <MobileNavItem onClick={() => {navigate("/expertise/"); setMobileOpen(false)}}>Expertise</MobileNavItem>
                <MobileNavItem onClick={() => {navigate("/workshops/"); setMobileOpen(false)}}>Workshops</MobileNavItem>
                {/* <MobileNavItem onClick={() => {navigate("/news/"); setMobileOpen(false)}}>News</MobileNavItem> */}
                <MobileNavItem onClick={() => {navigate("/contact/"); setMobileOpen(false)}}>Contact</MobileNavItem>
                </MobileItems>
            </MobileNav>
        </Fragment>
    )
}

export default Nav;
