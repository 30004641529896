import styled from "styled-components";
import React from "react"
import XImage from "./../../../images/x-bg.webp";

export const InnerPage = styled.div`
    max-width: 1500px;
    margin: 0 auto;
    padding:  0 1rem;
`;

export const WorkshopGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 800px) {
       flex-direction: column;
    }
`;

export const PageWrapper = styled.div`
    background: url(${XImage});
    padding: 1rem 0;
`;

//  Need to keep this here
const StylesPage = () => (
       <h1>404</h1>
)
export default StylesPage;